import { Action } from 'redux';
import { apiRoot } from '../../../../utils/apiroot';
import securedAxios from '../../../../utils/secured-axios';
import * as T from '../../../types/merchants/services';
import axios from 'axios';

// TODO: コンポーネントへの依存をやめる
import {
  nextFetchEndDate,
  nextFetchStartDate,
} from '../../../../molecules/merchant/calendar/calendarHelper';

// Types of Action
export enum Types {
  Load = 'merchant/services/Load',
  SwitchViewStyle = 'merchant/services/page/SwitchViewStyle',
  Init = 'merchant/services/page/Init',
  MovePrev = 'merchant/services/page/movePrev',
  MoveNext = 'merchant/services/page/moveNext',
  ScrollNext = 'merchant/services/page/scrollNext',
}

// Action and ActionCreator

// Init
export interface InitAction extends Action {
  type: Types.Init;
  payload: {
    publicId: string;
    timeZone: string;
  };
}

export const init = (publicId: string, timeZone: string): InitAction => {
  return {
    payload: { publicId, timeZone },
    type: Types.Init,
  };
};

// LoadAction
export interface LoadAction extends Action {
  type: Types.Load;
  payload: {
    meta?: T.Meta;
    services: T.Service[];
    fetchedUntil: string;
  };
}

export const load = async (
  publicId: string,
  baseDate: string,
  fetchedUntil: string,
): Promise<LoadAction | undefined> => {
  const fetchStartDate = nextFetchStartDate(fetchedUntil);
  const fetchEndDate = nextFetchEndDate(baseDate);

  // if services in next date range already have fetched,
  if (fetchStartDate > fetchEndDate) {
    return {
      type: Types.Load,
      payload: {
        services: [],
        fetchedUntil,
      },
    };
  }
  try {
    const res = await securedAxios.get<T.ResponseData>(
      `${apiRoot()}/merchants/${publicId}/booking_events?start=${fetchStartDate.toISO()}&end=${fetchEndDate.toISO()}`,
    );
    return {
      type: Types.Load,
      payload: {
        meta: res.data.meta,
        services: res.data.data,
        fetchedUntil: fetchEndDate.toISO(),
      },
    };
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      // TODO: ありえんぐらい404頻発してエラー捕捉されているのでとりあえずsentryを爆発させないよう握りつぶしている
      return;
    }
    throw e;
  }
};

// SwitchViewStyle
export interface SwitchViewStyleAction extends Action {
  type: Types.SwitchViewStyle;
  payload: {
    viewStyle: T.ViewStyle;
  };
}

export const switchViewStyle = (
  viewStyle: T.ViewStyle,
): SwitchViewStyleAction => {
  return {
    payload: { viewStyle },
    type: Types.SwitchViewStyle,
  };
};

// MovePrev
export interface MovePrevAction extends Action {
  type: Types.MovePrev;
}

export const movePrev = (): MovePrevAction => {
  return {
    type: Types.MovePrev,
  };
};

// MoveNext
export interface MoveNextAction extends Action {
  type: Types.MoveNext;
}

export const moveNext = (): MoveNextAction => {
  return {
    type: Types.MoveNext,
  };
};

// ScrollNext
export interface ScrollNextAction extends Action {
  type: Types.ScrollNext;
}

export const scrollNext = (): ScrollNextAction => {
  return {
    type: Types.ScrollNext,
  };
};

// Composing
export type Actions =
  | LoadAction
  | SwitchViewStyleAction
  | InitAction
  | MovePrevAction
  | MoveNextAction
  | ScrollNextAction;

export const ActionsCreators = {
  load,
  switchViewStyle,
  init,
  movePrev,
  moveNext,
  scrollNext,
};
