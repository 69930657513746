import axios, { AxiosInstance } from 'axios';
import getConfig from 'next/config';
import { getCookie } from './cookie';
import { coubicLocale } from './i18n';

const { publicRuntimeConfig } = getConfig();
let uaType = '';
let coubicLocaleString: coubicLocale | '' = '';

export function setUaType(uatype: string) {
  uaType = uatype || '';
}

export function setCoubicLocaleRequestHeader(locale: coubicLocale) {
  coubicLocaleString = locale;
}

// For SSR
export const newSecuredAxios = (params: {
  session?: string;
  token?: string;
  requestId?: string;
  merchantPublicId?: string;
}): AxiosInstance => {
  const headers: Record<string, string> = {
    'X-Request-Id': params.requestId ?? '',
    'X-Coubic-Locale': coubicLocaleString,
    'X-Merchant-Public-Id': params.merchantPublicId ?? '',
    'Api-Env': publicRuntimeConfig.coubicEnv,
    Uatype: uaType,
  };
  // if session is blank, fetch from cookie
  // need to fetch when intercepting for client side login
  const token = params.token || getCookie('token') || '';
  if (token) {
    headers['Access-Token'] = token;
  }

  const session = params.session || getCookie('cb') || '';
  if (session) {
    headers.Cookie = `cb=${encodeURIComponent(session)};`;
  }

  return axios.create({
    headers,
  });
};

// For CSR
const securedAxios = ((): AxiosInstance => {
  const ax = newSecuredAxios({});

  ax.interceptors.request.use((req) => {
    // TODO remove comment out after check if this instance is not used by SSR
    /*
    if (!isBrowser) { // TODO need to import from ./cookie
      throw new Error(
        // tslint:disable-next-line: quotemark
        "Don't use this axios on ServiceSide. use newSecuredAxios",
      );
    }
    */

    return req;
  });

  return ax;
})();

// securedAxiosがanonymous functionでラップされているため、誤検知されていると思われる
// もしくは、指摘の通りこの書き方だとFast Refresh時にlocal stateが保持されないのかもしれない
// ただ、いずれにしても対応は大変そうなので`eslint-disable`で対応します

// eslint-disable-next-line import/no-anonymous-default-export
export default securedAxios;
