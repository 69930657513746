import * as T from '../../../types/merchants/services';
import * as I from '../../../../interfaces/merchants/services';
import { Actions, Types } from '../../../actions/merchants/services';
// TODO: コンポーネントへの依存をやめる
import {
  buildCalendarDate,
  convertApiMetaInfoToApplicationInterface,
  convertApiResponseToApplicationInterface,
  dateTimeFromISO,
  DEFAULT_TZ,
  eachDayBetween,
  nextBaseDate,
  nextStartOfMonth,
  prevBaseDate,
  prevStartOfMonth,
  stopFetch,
  today,
  yesterday,
} from '../../../../molecules/merchant/calendar/calendarHelper';

export interface ServicesState {
  meta?: I.Meta;
  // FIXME I.ServiceMap型は内部にDate型を持っているが、ReduxのstateでDateクラスのインスタンスを扱うべきではない
  // 詳細はこちら参照 https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions
  services: I.ServiceMap;
  fetchedUntil: string;
  viewStyle: T.ViewStyle;
  baseDate: string; // ISO format
  publicId: string;
  timeZone: string;
}

const initialState: ServicesState = {
  services: {},
  viewStyle: T.ViewStyle.Calendar,
  baseDate: '',
  fetchedUntil: '',
  publicId: '',
  timeZone: DEFAULT_TZ,
};

export default (
  state: ServicesState = initialState,
  action: Actions,
): ServicesState => {
  switch (action.type) {
    case Types.Init: {
      const { publicId, timeZone } = action.payload;

      return {
        ...state,
        publicId,
        timeZone,
        baseDate: today(timeZone).toISO(),
        fetchedUntil: yesterday(timeZone).toISO(),
      };
    }
    case Types.Load: {
      const { services: newServices, fetchedUntil, meta } = action.payload;

      // apiリクエストしない場合、fetch済みのmetaを使う
      const imeta = meta
        ? convertApiMetaInfoToApplicationInterface(meta)
        : state.meta;
      const services = { ...state.services };
      newServices.map((s) => {
        // カレンダーの日付をyyyymmddで持ってkeyにしている
        const calendarDate = buildCalendarDate(s, imeta);
        const servicesBykey = services[calendarDate] || [];
        // to filter duplicate service
        const servicesByDigest: { [digest: string]: I.Service } = {};
        [...servicesBykey, convertApiResponseToApplicationInterface(s)].map(
          (service) => {
            servicesByDigest[service.digest] = service;
          },
        );

        services[calendarDate] = Object.values(servicesByDigest);
      });

      // 予約日程なしにより抜けてしまっている日付keyを補完
      const eachDates = eachDayBetween(
        today(state.timeZone),
        dateTimeFromISO(fetchedUntil),
      ).map((d) => d.toFormat('yyyyMMdd'));
      eachDates.forEach((date) => {
        if (!services[date]) {
          services[date] = [];
        }
      });

      return { ...state, services, fetchedUntil, meta: imeta };
    }
    case Types.SwitchViewStyle: {
      return {
        ...state,
        viewStyle: action.payload.viewStyle,
      };
    }
    case Types.MovePrev: {
      switch (state.viewStyle) {
        case T.ViewStyle.Calendar: {
          return { ...state, baseDate: prevBaseDate(state.baseDate).toISO() };
        }
        case T.ViewStyle.List: {
          return {
            ...state,
            baseDate: prevStartOfMonth(state.baseDate).toISO(),
          };
        }
        default:
          return state;
      }
    }
    case Types.MoveNext: {
      const { baseDate } = state;

      switch (state.viewStyle) {
        case T.ViewStyle.Calendar: {
          return { ...state, baseDate: nextBaseDate(baseDate).toISO() };
        }
        case T.ViewStyle.List: {
          return { ...state, baseDate: nextStartOfMonth(baseDate).toISO() };
        }
        default:
          return state;
      }
    }
    case Types.ScrollNext: {
      const { baseDate, fetchedUntil } = state;
      const nextDate = nextBaseDate(baseDate);
      if (stopFetch(baseDate, nextDate, fetchedUntil)) {
        return state;
      }

      return { ...state, baseDate: nextDate.toISO() };
    }
    default:
      return state;
  }
};
