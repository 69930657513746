import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

// Androidのwebviewでのみ発生している無視したいエラー
const androidWebViewErrorMessages = [
  "Unexpected token 'else'",
  "Unexpected token '='",
  "Unexpected identifier 'https'",
];

// see: https://developer.chrome.com/docs/multidevice/user-agent/#webview-on-android
function isAndroidWebView(ua) {
  return ua.includes('Android') && /; wv\)/.test(ua);
}

Sentry.init({
  ignoreErrors: [
    "ReferenceError: Can't find variable: _AutofillCallbackHandler",
    'Non-Error promise rejection captured',
    // https://github.com/heyinc/rsv-frontend/pull/1726
    'Load failed',
    'MyApp_RemoveAllHighlights',
    '__gCrWeb',
    'Script https://coubic.com/pushworker.js load failed',
    'Unexpected end of input',
    'onSvFinishLoading',
    // gtmにgtmDataLayer.push(arguments) するときに発生しているようです。原因は不明ですが放置されている状態であり発生件数も多いのでignoreの対応をいれました
    'TypeError: undefined is not an object (evaluating \'r["@context"].toLowerCase\')',
    'undefined is not an object (evaluating \'r[0]["@context"]\')',
    // https://github.com/getsentry/sentry-javascript/issues/8444
    "ReferenceError: Can't find variable: msDiscoverChatAvailable",
    "Unexpected token ')'",
    "Can't find variable: YAHOO",
    "Can't find variable: removeAllHighlightsForSB",
    'Munchkin is not defined',
    'Blocked a frame with origin "https://coubic.com"',
    '_error.js called with falsy error (undefined)',
    "ReferenceError: Can't find variable: IFrameMessageHandler",
  ],
  dsn:
    SENTRY_DSN ||
    'https://0dd49f1653604a899b6413337f510aa6@o104940.ingest.sentry.io/1484402',
  tracesSampleRate: 0.2,
  allowUrls: [/https:\/\/coubic\.com/],
  beforeSend(event, hint) {
    const ignoreFilenames = [`<anonymous>`];
    const error = hint.originalException;
    const ua = event.request.headers['User-Agent'];

    if (
      isAndroidWebView(ua) &&
      error &&
      error.message &&
      androidWebViewErrorMessages.includes(error.message)
    ) {
      return null;
    }

    try {
      if (
        ignoreFilenames.includes(
          event.exception.values[0].stacktrace.frames[0].filename,
        )
      ) {
        return null;
      }
    } catch (e) {}

    return event;
  },
});
